@import '~antd/dist/antd.css';

* {
    box-sizing: border-box;
}

html,
body,
#root {
    height: 100%;
}

body {
    font-family: 'GothamRounded', 'Helvetica Neue', 'Hiragino Sans', 'Yu Gothic', system-ui, sans-serif;
    padding: 0;
    margin: 0;
}

@font-face {
    font-family: 'GothamRounded';
    src: url('./fonts/gotham-rounded/GothamRounded-Medium.otf'),
    url('./fonts/gotham-rounded/gothamrounded-medium-webfont.eot'),
    url('./fonts/gotham-rounded/GothamRoundedMedium_21022.ttf') format('truetype');
}

@font-face {
    font-family: 'GothamRounded-LightItalic';
    src: url('./fonts/gotham-rounded/GothamRounded-LightItalic.otf'),
    url('./fonts/gotham-rounded/gothamrounded-light-webfont.eot'),
    url('./fonts/gotham-rounded/GothamRoundedLight_21020.ttf') format('truetype');
}

@font-face {
    font-family: 'GothamRounded-Bold';
    src: url('./fonts/gotham-rounded/GothamRounded-Bold.otf'),
    url('./fonts/gotham-rounded/gothamrounded-bold-webfont.eot'),
    url('./fonts/gotham-rounded/GothamRoundedBold_21016.ttf') format('truetype');
}

@font-face {
    font-family: 'GothamRounded-Light';
    src: url('./fonts/gotham-rounded/GothamRounded-Light.otf'),
    url('./fonts/gotham-rounded/gothamrounded-light-webfont.eot'),
    url('./fonts/gotham-rounded/GothamRoundedLight_21020.ttf') format('truetype');
}

@font-face {
    font-family: 'GothamRounded-Book';
    src: url('./fonts/gotham-rounded/GothamRounded-Book.otf'),
    url('./fonts/gotham-rounded/gothamrounded-medium-webfont.eot'),
    url('./fonts/gotham-rounded/GothamRoundedBook_21018.ttf') format('truetype');
}

@font-face {
    font-family: 'GothamRounded-BookItalic';
    src: url('./fonts/gotham-rounded/GothamRounded-BookItalic.otf'),
    url('./fonts/gotham-rounded/gothamrounded-mediumitalic-webfont.eot'),
    url('./fonts/gotham-rounded/GothamRoundedBook_21018.ttf') format('truetype');
}

@font-face {
    font-family: 'GothamRounded-Ultra';
    src: url('./fonts/gotham-rounded/Gotham-Ultra.otf');
}

.ant-form-item-has-error input {
    border: 1px solid rgb(215,16,27) !important;
}
